
const SearchCount2 = ({ count, total, className, ...props }) => {

    return (
        <div {...props} className={'ms-2 mb-1 text-muted' + (className ? ` ${className}` : '')} >

            <small>
                { total > 0 && count === total && `(${count})` }
                { count !== total && `(${count}/${total})` }
            </small>

        </div>
    )
}

export default SearchCount2;