
import axios from "axios";
import AppLogic from '../logic/AppLogic.js';
import FilterUtil from '../utils/FilterUtil.js';

const SERVER_URL = `${AppLogic.serverUrl}/api/motions`;

const MotionService = () => {

    const getMotion = async (id) => {
        return (await axios.get(`${SERVER_URL}/${id}`)).data.payload;
    }

    const getMotions = async (company, filter) => {
        const motions = (await axios.get(`${SERVER_URL}?company=${company.id}`)).data.payload;
        return FilterUtil.filter(motions, filter);
    }

    const getTotalCount = async (company) => {
        return (await axios.get(`${SERVER_URL}/count?company=${company.id}`)).data.payload;
    }

    const getMatchingsForMotion = async (motionId, wishes) => {
        return (await axios.put(`${SERVER_URL}/matchings?motion=${motionId}`, { wishes })).data.payload;
    }

    const getMatchingsForUser = async (userId, wishes) => {
        return (await axios.put(`${SERVER_URL}/matchings?user=${userId}`, { wishes })).data.payload;
    }

    const create = async (company, owner, lang) => {
        return (await axios.get(`${SERVER_URL}/create?company=${company.id}&owner=${owner.id}&lang=${lang}`)).data.payload;
    }

    const isDeletable = async (motion) => {
        return (await axios.get(`${SERVER_URL}/${motion.id}/deletable`)).data.payload;
    }

    const save = async (motion) => {

        return motion.id
            ? (await axios.put(`${SERVER_URL}/${motion.id}`, motion)).data.payload
            : (await axios.post(`${SERVER_URL}`, motion)).data.payload;
    }

    const clone = async (motion) => {
        return (await axios.post(`${SERVER_URL}/${motion.id}/clone`)).data.payload;
    }

    const remove = async (motion) => {
        await axios.delete(`${SERVER_URL}/${motion.id}`);
    }

    return {
        getMotions,
        getMotion,
        getTotalCount,
        getMatchingsForMotion,
        getMatchingsForUser,
        create,
        isDeletable,
        save,
        clone,
        remove
    }
}

export default MotionService();