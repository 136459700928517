
import { useState } from 'react';
import { useAuthContext } from '../controllers/useAuthContext.js';
import useUserController from '../controllers/useUserController.js';
import useCompanyController from '../controllers/useCompanyController.js';
import useCatalogController from '../controllers/useCatalogController.js';

import StringUtil from '../utils/StringUtil.js';
import MotionService from '../api/MotionService.js';
import UserService from '../api/UserService.js';

const useMotionController = (filter) => {

    /**
     * Component state
     */

    const { auth } = useAuthContext();

    const [motions, setMotions] = useState([]);
    const [loaded, setLoaded] = useState(true);
    const [totalCount, setTotalCount] = useState(0);

    const userController = useUserController();
    const companyController = useCompanyController();
    const catalogController = useCatalogController();

    /**
     * Functionalities
     */

    const reload = async () => {

        try {
            setLoaded(false);
            const motions = await MotionService.getMotions(auth.company, filter);
            motions.forEach(m => translate(m));
            setMotions(motions);

            const totalCount = await MotionService.getTotalCount(auth.company);
            setTotalCount(totalCount);
        }
        finally {
            setLoaded(true);
        }
    }

    const deletable = async (motion) => {
        return motion.id != null && await MotionService.isDeletable(motion);
    }

    const getMatchingsForMotion = async (motion, wishes) => {

        const matchings =
            (await MotionService.getMatchingsForMotion(motion.id, wishes))
                .map(user => {
                    user.distances = new Map(user.distances);
                    user.label = StringUtil.toFirstLetters(user.firstname + ' ' + user.lastname);
                    user.like = motion.likes.includes(user.id);
                    return user;
                })
                .sort((a, b) => {
                    return (a.distances).get('absolute') - b.distances.get('absolute');
                })

        return matchings;
    }

    const getMatchingsForUser = async (user, wishes) => {

        const matchings =
            (await MotionService.getMatchingsForUser(user.id, wishes))
                .map(motion => {
                    motion.distances = new Map(motion.distances);
                    motion.label = StringUtil.toFirstLetters(motion.name);
                    motion.picture = motion.company.picture;
                    motion.like = motion.likes.includes(user.id);
                    return motion;
                })
                .sort((a, b) => {
                    return (a.distances).get('absolute') - b.distances.get('absolute');
                })

        return matchings;
    }

    const remove = async (motion) => {
        await MotionService.remove(motion);
        reload();
    }

    const translate = (motion) => {
        motion.company && companyController.translate(motion.company);
        motion.owner && userController.translate(motion.owner);
        motion.catalog && catalogController.translate(motion.catalog);
    }

    const untranslate = (motion) => {
        motion.company && companyController.untranslate(motion.company);
        motion.owner && userController.untranslate(motion.owner);
        motion.catalog && catalogController.untranslate(motion.catalog);
    }

    const retranslate = (motion) => {

        if (motion) {
            untranslate(motion);
            translate(motion);
        }
        else {

            motions.forEach(motion => {
                untranslate(motion);
                translate(motion);
            })

            setMotions([...motions]);
        }
    }

    const getMotion = async (motionId) => {

        const id = motionId === 'new' ? null : motionId;

        // Get motion and initialize it
        const motion = id
            ? await MotionService.getMotion(id)
            : await MotionService.create(auth.company, auth.user, auth.lang);

        motion.label = StringUtil.toFirstLetters(motion.name);
        motion.picture = motion.company.picture;

        translate(motion);

        // Get the categories from the catalog
        motion.categories = motion.catalog?.children ?? [];

        // Ensure that user has wishes
        motion.wishes = motion.wishes ?? [];

        // Init default axes
        if (motion.axes.x === '' && motion.categories.length > 0) motion.axes.x = motion.categories[0].id;
        if (motion.axes.y === '' && motion.categories.length > 1) motion.axes.y = motion.categories[1].id;
        if (motion.axes.z === '' && motion.categories.length > 2) motion.axes.z = motion.categories[2].id;

        return motion;
    }

    const getMotionUser = async (userId) => {

        // Get user and initialize it
        const user = await UserService.getUser(userId);
        user.label = StringUtil.toFirstLetters(user.firstname + ' ' + user.lastname);

        // Get the company catalog
        const catalog = await catalogController.getCompanyCatalog(auth.user.company);

        // Get the skill's categories
        user.categories = catalog?.children ?? [];

        // Ensure that user has wishes
        user.wishes = user.wishes ?? [];

        // Init default axes
        if (user.axes?.x === '' && user.categories.length > 0) user.axes.x = user.categories[0].id;
        if (user.axes?.y === '' && user.categories.length > 1) user.axes.y = user.categories[1].id;
        if (user.axes?.z === '' && user.categories.length > 2) user.axes.z = user.categories[2].id;

        return user;
    }

    const save = async (motion) => {

        try {
            untranslate(motion);
            motion.id = await MotionService.save(motion);
        }
        finally {
            translate(motion);
        }

        reload();
    }

    const clone = async (motion) => {
        await MotionService.clone(motion);
        reload();
    }

    const like = async (user, motion) => {

        motion.like = !motion.like;

        if (motion.like) {
            motion.likes.push(user.id);
        }
        else {
            motion.likes = motion.likes.filter(uid => uid !== user.id);
        }

        await save({ ...motion });
    }

    return {
        motions,
        totalCount,
        loaded,
        reload,
        deletable,
        getMatchingsForMotion,
        getMatchingsForUser,
        remove,
        translate,
        untranslate,
        retranslate,
        getMotion,
        getMotionUser,
        save,
        clone,
        like
    }
}

export default useMotionController;