
import { useTranslation } from 'react-i18next';

const Paginator = ({ page, total, className, onChange, ...props }) => {

    const { t } = useTranslation();
    const previousDisabled = page === 0;
    const nextDisabled = page === total - 1;

    if (total > 1) {

        return (
            <nav>
                <ul {...props} className={'mt-3 pagination justify-content-center' + (className ? ` ${className}` : '')}>

                    <li className={'page-item' + (previousDisabled ? ' disabled' : '')}>
                        <button className='page-link' onClick={() => onChange(page-1)}>{t('button.previous')}</button>
                    </li>

                    {
                        [...Array(total ?? 0).keys()].map(pageNumber => {
                            return (
                                <li key={pageNumber} className={'page-item' + (pageNumber === page ? ' active' : '')}>
                                    <button className='page-link' onClick={() => onChange(pageNumber)}>{pageNumber + 1}</button>
                                </li>)
                        })
                    }

                    <li className={'page-item' + (nextDisabled ? ' disabled' : '')}>
                        <button className='page-link' onClick={() => onChange(page+1)}>{t('button.next')}</button>
                    </li>
                    
                </ul>
            </nav>)
    }
}

export default Paginator;