
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useUserController from '../../../controllers/useUserController.js';

import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import SearchBar2 from '../../../components/filter/SearchBar2.js';
import SearchCount2 from '../../../components/filter/SearchCount2.js';
import TableHeader from '../../../components/filter/TableHeader.js';
import DateUtil from '../../../utils/DateUtil.js';
import StringUtil from '../../../utils/StringUtil.js';
import Paginator from '../../../components/filter/Paginator.js';
import UserDetail from './UserDetail.js';
import UserDelete from './UserDelete.js';

import new_img from '../../../assets/images/plus.svg';
import edit_img from '../../../assets/images/pen.svg';

const Users = () => {


    const columns = [
        { name: 'email', accessor: 'email', display: 'users.email', sortable: true },
        { name: 'firstname', accessor: 'firstname', display: 'users.firstname', sortable: true },
        { name: 'lastname', accessor: 'lastname', display: 'users.lastname', sortable: true },
        { name: 'roles', accessor: 'roles', display: 'glossary.userrole', sortable: false },
        { name: 'company', accessor: 'company.name', display: 'glossary.company', sortable: true },
        { name: 'usertype', accessor: 'userType.name', display: 'glossary.usertype', sortable: true },
        { name: 'team', accessor: 'team', display: 'users.team', sortable: true }
    ]

    /**
     * Page state
     */

    const { t } = useTranslation();
    const { auth } = useAuthContext();
    const [user, setUser] = useState({});
    const [users, setUsers] = useState([]);
    const [filter, setFilter] = useState({ columns });
    const [pagination, setPagination] = useState({});

    const userController = useUserController();

    /**
     * Page lifecycle
     */

    useEffect(() => {

        (async () => {

            const data = await userController.getUsersWithPagination({ ...filter, limit: pagination.limit, page: pagination.page });
            setUsers(data.items);
            setPagination(data.pagination);

        })()

        // eslint-disable-next-line 
    }, [filter]);

    useEffect(() => {

        userController.retranslate(users);
        setUsers([...users]);
        setFilter({ ...filter, column: [...filter.columns] });

        // eslint-disable-next-line 
    }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleNew = async () => {
        const user = await userController.create();
        setUser(user);
    }

    const handleDetail = (user) => {
        setUser(user);
    }

    const handleSave = async (user) => {
        await userController.save(user);
        setFilter({ ...filter });
    }

    const handleDelete = async (user) => {
        await userController.remove(user);
        setFilter({ ...filter });
    }

    const handleSearch = (text) => {
        setFilter({ ...filter, text });
    }

    const handleSort = (column) => {
        const columns = filter.columns.map(c => c.name === column.name ? column : c);
        setFilter({ ...filter, columns });
    }

    const handlePagination = (page) => {
        setPagination({ ...pagination, page });
        setFilter({ ...filter });
    }

    return (

        <div className='container'>

            <LoadingIcon loading={!userController.loaded} />
            <UserDetail id='detail' user={user} onSave={handleSave} />
            <UserDelete id='delete' user={user} onDelete={handleDelete} />

            <div className='row p-4'>

                <div className='d-flex align-items-center'>
                    <h5 className='text-muted mb-1'>{t('glossary.users')}</h5>
                    <SearchCount2 count={users.length} total={pagination.total} />
                    <SearchBar2 text={filter.text} onChange={handleSearch} />
                </div>

                <hr />

                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <TableHeader name='email' columns={filter.columns} onSort={handleSort} />
                            <TableHeader name='firstname' columns={filter.columns} onSort={handleSort} />
                            <TableHeader name='lastname' columns={filter.columns} onSort={handleSort} />
                            <TableHeader name='roles' columns={filter.columns} onSort={handleSort} />
                            <TableHeader name='company' columns={filter.columns} onSort={handleSort} />
                            <TableHeader name='team' columns={filter.columns} onSort={handleSort} />
                            <TableHeader name='usertype' columns={filter.columns} onSort={handleSort} />
                            <th>
                                {t('users.lastlogin')}
                            </th>
                            <th className='text-center'>
                                <button className='btn btn-light' data-bs-toggle='modal' data-bs-target='#detail' onClick={handleNew}>
                                    <img src={new_img} alt='new' />
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            users.map((user) => (
                                <tr key={user.id} className={'align-middle' + (user.disabled ? ' text-muted' : '')}>
                                    <td>{user.email}</td>
                                    <td>{user.firstname}</td>
                                    <td>{user.lastname}</td>
                                    <td>{user.roles?.map(role => role.name).join(', ')}</td>
                                    <td>{user.company ? user.company.name : '-'}</td>
                                    <td>{StringUtil.isEmpty(user.team) ? '-' : user.team}</td>
                                    <td>{user.userType ? user.userType.name : '-'}</td>
                                    <td className='text-center'>{DateUtil.toLocaleString(user.lastlogin)}</td>
                                    <td className='text-center'>
                                        <div className='btn-group'>
                                            <button className='btn btn-light' data-bs-toggle='modal' data-bs-target='#detail' onClick={() => handleDetail(user)}><img src={edit_img} alt='edit' /></button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

                <Paginator page={pagination.page} total={pagination.pages} onChange={handlePagination} />

            </div>
        </div>
    )
}

export default Users
