
import { useAuthContext } from '../controllers/useAuthContext.js';
import CatalogService from '../api/CatalogService.js';
import ObjectUtil from "../utils/ObjectUtil.js";
import TreeUtil from '../utils/TreeUtil.js';
import LangUtil from "../utils/LangUtil.js";
import FileUtil from "../utils/FileUtil.js";
import InventoryLogic from '../logic/InventoryLogic.js';

const useCatalogController = () => {

    const { auth } = useAuthContext();

    const getMasterCatalog = async () => {
        const catalog = await CatalogService.getMasterCatalog();
        translate(catalog);
        return catalog; 
    }

    const getGlobalCatalog = async () => {
        const catalog = await CatalogService.getGlobalCatalog();
        translate(catalog);
        return catalog;
    }

    const getUserCatalog = async (user) => {
        const catalog = await CatalogService.getUserCatalog(user);
        translate(catalog);
        return catalog;
    }

    const getReviewCatalog = async (reviewer, user) => {
        const catalog = await CatalogService.getReviewCatalog(reviewer, user);
        translate(catalog);
        return catalog;
    }

    const getCompanyCatalog = async (company) => {
        const catalog = await CatalogService.getCompanyCatalog(company);
        translate(catalog);
        return catalog;
    }

    const getCompanyInventoryCatalog = async (company) => {
        const data = await CatalogService.getCompanyInventoryCatalog(company);
        InventoryLogic.initializeAgeRange(data.users);
        translate(data.catalog);
        return data;
    }

    const createSurveyCatalog = async () => {
        const catalog = await CatalogService.createSurveyCatalog(auth.company);
        translate(catalog);
        return catalog;
    }

    const getSurveyCatalog = async (survey) => {
        const catalog = await CatalogService.getSurveyCatalog(survey)
        translate(catalog);
        return catalog;
    }    

    const getSurveyInventoryCatalog = async (survey) => {
        const data = await CatalogService.getSurveyInventoryCatalog(survey);
        InventoryLogic.initializeAgeRange(data.users);
        translate(data.catalog);
        return data;
    }

    const getMotionCatalog = async (motion) => {
        return motion.id && await CatalogService.getMotionCatalog(motion);
    }

    const saveGlobalCatalog = async (catalog) => {
        const clone = ObjectUtil.clone(catalog);
        untranslate(clone);        
        await CatalogService.saveGlobalCatalog(clone);
    }

    const saveCompanyCatalog = async (company, catalog) => {
        const clone = ObjectUtil.clone(catalog);
        untranslate(clone);        
        await CatalogService.saveCompanyCatalog(company, clone);
    }

    const saveUserCatalog = async (user, catalog) => {
        const clone = ObjectUtil.clone(catalog);
        untranslate(clone);        
        await CatalogService.saveUserCatalog(user, clone);
    }
    
    const saveSurveyCatalog = async (survey, catalog) => {
        const clone = ObjectUtil.clone(catalog);
        untranslate(clone);        
        await CatalogService.saveSurveyCatalog(survey, clone);
    }
    
    const saveReviewCatalog = async (reviewer, user, catalog) => {
        const clone = ObjectUtil.clone(catalog);
        untranslate(clone);        
        await CatalogService.saveReviewCatalog(reviewer, user, clone);
    }
    
    const importMasterCatalog = async (filename) => {
        const catalog = await FileUtil.readJson(filename);
        catalog && await CatalogService.saveMasterCatalog(catalog);
    }

    const exportCatalog = (catalog) => {

        // Clean the catalog (multilang)
        const data = ObjectUtil.clone(catalog);
        untranslate(data);
        TreeUtil.toList(data).forEach(node => delete node.state );
    
        // And put it into a blob
        const blob = new Blob([JSON.stringify(data, null, 2)], { type: "application/json" });

        // Create a href pointing the catalog file (json)
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = 'skillspot-skillset.json';

        // Simulate user click
        a.click();
    }

    const translate = (tree) => {
        LangUtil.translateTree(tree, auth.lang);
    }

    const untranslate = (catalog) => {
        LangUtil.untranslateTree(catalog);
    }

    const retranslate = (catalog) => {
        LangUtil.untranslateTree(catalog);
        LangUtil.translateTree(catalog, auth.lang);
    }

    return {
        getMasterCatalog,
        getGlobalCatalog,
        getCompanyCatalog,
        getUserCatalog,
        getReviewCatalog,
        getSurveyCatalog,
        getCompanyInventoryCatalog,
        getSurveyInventoryCatalog,
        getMotionCatalog,

        createSurveyCatalog,
        
        saveGlobalCatalog,
        saveCompanyCatalog,
        saveUserCatalog,
        saveSurveyCatalog,
        saveReviewCatalog,

        exportCatalog,
        importMasterCatalog,

        translate,
        untranslate,
        retranslate
    }
}

export default useCatalogController;