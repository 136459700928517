
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useMotionController from '../../../controllers/useMotionController.js';
import useUserController from '../../../controllers/useUserController.js';

import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import WishesView from '../../../components/radar/WishesView.js';
import AxesView from '../../../components/radar/AxesView.js';
import Radar from '../../../components/radar/Radar.js';
import OpportunityView from '../../../components/radar/OpportunityView.js';

import save_img from '../../../assets/images/save.svg';
import filter_on_img from '../../../assets/images/filter-on.svg';
import filter_off_img from '../../../assets/images/filter-off.svg';
import reset_icon from '../../../assets/images/reset.svg';
import axes_icon_img from '../../../assets/images/axes.svg';

import './Opportunities.css';

const Opportunities = () => {

    /**
     * Page state
     */

    const { auth } = useAuthContext();
    const { t } = useTranslation();

    const [user, setUser] = useState({});
    const [wishes, setWishes] = useState([]);
    const [axes, setAxes] = useState([]);
    const [motion, setMotion] = useState({});
    const [matchings, setMatchings] = useState([]);
    const [hlabel, setHLabel] = useState('');
    const [vlabel, setVLabel] = useState('');

    const [wishesInitialized, setWishesInitialized] = useState(false);
    const [resetRadarCallback, setResetRadarCallback] = useState();

    const [loaded, setLoaded] = useState(true);
    const [showFilter, setShowFilter] = useState(true);
    const [showProfile, setShowProfile] = useState(false);

    const motionController = useMotionController();
    const userController = useUserController();

    /**
     * Page lifecycle
     */

    // First time, reset radar scale + position (a few ms after flex layout is completed)
    useEffect(() => { wishesInitialized && resetRadarCallback && setTimeout(() => resetRadarCallback(), 200) }, [wishesInitialized, resetRadarCallback]);

    // Each time lang changed, get initial user, wishes and axes
    useEffect(() => { 
    
        (async () => {

            try {
                setLoaded(false);
    
                // Get the user fully initialize from motion controller
                const user = await motionController.getMotionUser(auth.user.id);
    
                // Update view state
                setUser(user);
                setWishes(user.wishes);
                setAxes(user.axes);
            }
            finally {
                setLoaded(true);
            }

        })()

        // eslint-disable-next-line
    }, [auth.lang]);

    // Each time the axes change, reevaluate the axes labels
    useEffect(() => {

        const xaxis = user.categories?.find(w => w.id === axes.x);
        const yaxis = user.categories?.find(w => w.id === axes.y);

        setHLabel(xaxis?.label ?? 'n/a');
        setVLabel(yaxis?.label ?? 'n/a');

        // eslint-disable-next-line
    }, [user, axes])

    // Each time the wishes change, reevaluate the matchings
    useEffect(() => {

        (async () => {

            try {
                if (user.id) {

                    const matchings = await motionController.getMatchingsForUser(user, wishes);
                    setMatchings(matchings);
                }
            }
            catch (e) {
                console.log(e);
            }

        })();

        // eslint-disable-next-line
    }, [wishes]);

    /**
     * Event handlers
     */

    const handleWishesInit = () => {
        setWishesInitialized(true);
    }

    const handleRadarInit = (callback) => {
        setResetRadarCallback(() => callback); // useState cannot store function directly
    }

    const handleReset = () => {
        resetRadarCallback && resetRadarCallback(); // radar reset scale + position
        // setUser({ ...user }); // if the user change, the radar reset its scale + position
    }

    const handleWishesChange = ({ wishes }) => {
        setWishes(wishes);
    }

    const handleAxesChange = (axes) => {
        setAxes(axes);
    }

    const handleShowProfile = (motion) => {
        setMotion(motion);
        setShowProfile(true);
    }

    const handleHideProfile = () => {
        setShowProfile(false);
    }

    const handleFilter = () => {
        setShowFilter(!showFilter);
    }

    const handleSave = () => {
        setShowProfile(false);
        userController.save({ ...user, wishes, axes });
    }

    const handleLike = () => {
        setShowProfile(false);
        motionController.like(user, motion);
    }

    return (

        <div className='container-fluid'>
            <div className='row p-4'>

                <LoadingIcon loading={!loaded} />
                <AxesView id='axes' axes={axes} categories={user.categories} onChange={handleAxesChange} />
                <OpportunityView show={showProfile} motion={motion} onLike={handleLike} onClose={handleHideProfile} />

                <div className='d-flex align-items-center'>
                    <h5 className='text-muted mb-1'>{t('profile.opportunities')}</h5>
                </div>

                <hr />

                <div id='opportunities' className='d-flex'>

                    <div id='wishes' className={'border rounded p-2 me-3 collapse collapse-horizontal show'}>
                        <WishesView wishes={wishes} onInit={handleWishesInit} onChange={handleWishesChange} />
                    </div>

                    <div className='border rounded p-2 flex-fill'>

                        <div id='opportunities-toolbar' className='btn-group'>
                            <button className='btn btn-light' onClick={handleSave}><img src={save_img} alt='save' /></button>
                            <button className='btn btn-light' data-bs-toggle='collapse' data-bs-target='#wishes' onClick={handleFilter}><img src={showFilter ? filter_off_img : filter_on_img} alt='filter' /></button>
                            <button className='btn btn-light' onClick={handleReset}><img src={reset_icon} alt='reset' /></button>
                            <button className='btn btn-light' data-bs-toggle='modal' data-bs-target='#axes'><img src={axes_icon_img} alt='axes' /></button>
                        </div>

                        <Radar entity={user} axes={axes} matchings={matchings} hlabel={hlabel} vlabel={vlabel} onInit={handleRadarInit} onSelect={handleShowProfile} />

                    </div>

                </div>

            </div>
        </div>
    )
}

export default Opportunities;
