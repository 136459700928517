
import { useTranslation } from 'react-i18next';
import unsort_img from '../../assets/images/sort-none.svg'; 
import sort_ascending_img from '../../assets/images/sort-down.svg'; 
import sort_descending_img from '../../assets/images/sort-up.svg'; 

import './TableHeader.css';

const TableHeader = ({ name, columns, className, onSort, ...props }) => {
    
    const { t } = useTranslation();
    const column = columns.find(column => column.name === name);

    const handleClick = (e) => {

        if (column.ascending) {
            column.ascending = false;
            column.descending = true;
        }
        else if (column.descending) {
            column.ascending = false;
            column.descending = false;
        }
        else {
            column.ascending = true;
            column.descending = false;
        }

        onSort(column);
    }

    return (
        // <th {...props} className='text-center'>
        <th>
            <div className={`table-header-new ${column.sortable ? 'sortable' : ''} ${className}`} onClick={handleClick}>

                { t(column.display) }

                {
                    column.sortable &&
                    <div className='ps-1' onClick={handleClick}>
                        { column.ascending && <img src={sort_ascending_img } alt='ascending sort' /> }
                        { column.descending && <img src={sort_descending_img } alt='descending sort' /> }
                        { !column.ascending && !column.descending && <img src={unsort_img } alt='unsorted' /> }
                    </div>
                }

            </div>
        </th>
    )
}

export default TableHeader;