
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import './SearchBar2.css'

const SearchBar2 = ({ text, className, onChange, ...props }) => {

    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(false);
    const [inputText, setInputText] = useState(text);

    const handleChange = (e) => {

        const inputText = e.target.value;

        setExpanded(inputText);
        setInputText(inputText);
        
        onChange(e.target.value);
    }

    const handleUnfocus = () => {
        if (text?.length < 1) setExpanded(false);
    }

    return (

        <div {...props} className={'searchbar d-flex flex-fill justify-content-end align-items-center ms-4' + (className ? ` ${className}` : '')}>
            <input
                type='search' 
                className='form-control' 
                placeholder={t('glossary.search') + '...'} 
                value={inputText ?? ''} 
                style={{ width: (expanded ? '100%' : '7.5rem') }} 
                onChange={handleChange} 
                onBlur={handleUnfocus} />
        </div>
    )
}

export default SearchBar2