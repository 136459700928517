
import { useTranslation } from 'react-i18next';
import star_img from '../../assets/images/star-outline.svg';
import star_strike_img from '../../assets/images/star-outline-strike.svg';

const OpportunityView = ({ show, motion, onLike, onClose }) => {

    /**
     * Page state
     */

    const { t } = useTranslation();

    /**
     * Event handlers
     */

    const handleLike = () => {
        onLike && onLike();
    }

    const handleClose = () => {
        onClose && onClose();
    }

    return (
        <div className={'modal fade ' + (show ? 'show' : '')} style={{ display: show ? 'block' : 'none' }}>

            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>

                    <div className='modal-header'>
                        <h2 className='modal-title'>{t('glossary.opportunity')}</h2>
                        <button type='button' className='btn-close' onClick={handleClose}></button>
                    </div>

                    <div className='modal-body'>
                        <div className='container'>

                            <div className='row'>
                                <div className='form-group'>
                                    <label htmlFor='name'>{t('companies.name')}</label>
                                    <input id='name' type='text' className='form-control' value={motion.company?.name ?? ''} onChange={() => { }} />
                                </div>
                            </div>

                            <div className='row pt-2'>
                                <div className='form-group'>
                                    <label htmlFor='position'>{t('motions.position-title')}</label>
                                    <input id='position' type='text' className='form-control' value={motion.name ?? ''} onChange={() => { }} />
                                </div>
                            </div>

                            <div className='row pt-2'>
                                <div className='form-group'>
                                    <label htmlFor='description'>{t('motions.position-description')}</label>
                                    <textarea id='description' className='form-control' rows='3' value={motion.description ?? ''} onChange={() => { }} />
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='modal-footer'>
                        <div className='d-flex justify-content-between w-100'>

                            {
                                !motion.like &&
                                <button className='btn btn-light' onClick={handleLike}>
                                    {t('button.like')} &nbsp; <img src={star_img} alt='like' />
                                </button>
                            }
                            {
                                motion.like &&
                                <button className='btn btn-light' onClick={handleLike}>
                                    {t('button.unlike')} &nbsp; <img src={star_strike_img} alt='unlike' />
                                </button>
                            }

                            <button className='btn btn-light' onClick={handleClose}>{t('button.close')}</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default OpportunityView;
